import {AuthorizedRepresentative} from './kommdata.model';
import {ErrorType} from '../../komm-data-change/model/komm-data-change-response.model';

export interface ConsentElement {
  consentType: ConsentType;
  emailForConsent: string;
  mobileNumberForConsent: string;
  authorizedRepresentative: AuthorizedRepresentative;
  errorType: ErrorType;
}

export enum ConsentType {
  ESB = 'ESB', AVP = 'AVP', WEWE = 'WEWE', SBS = 'SBS', MAZ = 'MAZ', DEWE = 'DEWE'
}
