<form #form="ngForm" (submit)="onSubmit()">
  <div nxLayout="grid maxwidth" class="contact-data-input-remove-not-available contact-data-input-remove-info">
    <div class="u-text-align-center nx-margin-top-xl" nxRow nxRowJustify="center">
      <div class="nx-margin-top-xl" nxCol="12,8,8,6">
        <div class="card-header">
          <span class="nx-font-weight-semibold u-text-align-center">Digitales Kundenprofil</span>
          <h2 nxHeadline="section" class="u-text-align-center margin-bottom-8">Programmteilnahmen</h2>
          <p nxCopytext="normal"
             class="u-text-align-center">Bitte wählen Sie, zu welchen weiteren Allianz-Programmen Sie den Kunden
            einladen
            möchten</p>
        </div>

        <div class="u-text-align-center programm-participation-wrapper " nxRow nxRowJustify="center">
          <div class="nx-margin-top-s" nxCol="1">
            <nx-icon name="check-circle" class="icon-check" size="s"></nx-icon>
          </div>
          <div class="nx-margin-top-s u-text-align-left programm-detail" nxCol="10">
            <p class="nx-margin-0" nxCopytext="normal">
              <strong>Meine Allianz</strong> <br>
              Dieser Kunde nutzt Meine Allianz mit den folgenden Kommunikationsdaten:<br>
            </p>
            <div nxCopytext="normal">
              <div nxRow>
                <div nxCol="4">
                  E-Mail-Adresse
                </div>
                <div nxCol="8">
                  <strong>
                    {{mazEmail}}
                  </strong>
                </div>
              </div>
              <div nxRow>
                <div nxCol="4">
                  Mobilfunknummer
                </div>
                <div nxCol="8">
                  <strong>
                    {{mazMobileNumber | mobileNumberFormat}}
                  </strong>
                </div>
              </div>
            </div>
            <p class="nx-margin-top-s" nxCopytext="medium">
              Diese Daten werden für Programm-Einladungen verwendet.
              <nx-link nxStyle="text" >
                <a nxCopytext="medium" (click)="navigateToKommDataChange()" class="nx-margin-bottom-0" >
                  Eine Änderung der mAZ-Kommunikationsdaten ist hier möglich.
                </a>
              </nx-link>
            </p>
          </div>
        </div>

        <div nxRow *ngIf="displayedActivatedProgramParticipations.length > 0">
          <div class="nx-margin-top-s active-programm-participation-label" nxCol="12">
            <nx-label>Aktivierte Programmteilnahmen</nx-label>
          </div>
        </div>

        <div *ngFor="let program of displayedActivatedProgramParticipations"
             class="u-text-align-center programm-participation-wrapper" nxRow nxRowJustify="center">
          <div class="nx-margin-top-s" nxCol="1">
            <nx-icon [ngClass]=program.status.iconClass
                     [name]=program.status.iconName size="s"></nx-icon>
          </div>
          <div class="nx-margin-top-s u-text-align-left programm-detail" nxCol="10">
            <p class="nx-margin-0" nxCopytext="normal">
              <strong>{{program.type | displayedNameProgramType}} </strong> <br>
              {{program.type | longVersionProgramType}}
            </p>
          </div>
        </div>

        <div *ngIf="isInvitationPossible" nxRow>
          <div class="nx-margin-top-l u-text-align-left" nxCol="12">
            <nx-checkbox-group [(ngModel)]="invitationChoices" name="invitationChoice"
                               (selectionChange)="setIsInvitationButtonDisabled()">
              <nx-label>Zu weiteren Programmen einladen</nx-label>
              <nx-checkbox *ngFor="let program of programInvitations" [value]="program.type" class="nx-margin-bottom-s">
                <strong>{{program.type | displayedNameProgramType}}</strong><br>
                {{program.type | longVersionProgramType}}
              </nx-checkbox>
            </nx-checkbox-group>
          </div>
        </div>
        <nx-message *ngIf="showError" nxContext="error">
          Leider konnte die Programm-Einladung nicht versendet werden. Bitte versuchen Sie es später erneut.
        </nx-message>
      </div>
    </div>


    <div class="nx-margin-top-4m" nxRow nxRowJustify="center">
      <div class="button-wrapper dssbs-padding-bottom-s" nxCol="12,12,3" nxAlignSelf="center,center,end"
           nxColOrder='first,first,last,last'>
        <button *ngIf="isInvitationPossible" class="button-right-small-up nx-margin-bottom-0"
                [disabled]=isInvitationButtonDisabled
                nxButton="primary" type="submit">
          Einladungen senden
        </button>
      </div>

      <div class="button-wrapper link-text-center-small dssbs-padding-bottom-s" nxCol="12,12,3" nxAlignSelf="center">
        <button nxPlainButton type="button" (click)="navigateBack()" class="nx-margin-bottom-0">
          ZURÜCK
        </button>
      </div>
    </div>

    <div nxRow nxRowJustify="center" class="nx-padding-top-s">
      <div nxCol="12,12,6">
        <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
      </div>
    </div>
  </div>
</form>
