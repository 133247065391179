import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  NXT_CONTACT_DATA_INPUT_CONTACT_TYPES,
  NxtContactDataInputComponent,
  NxtContactDataInputModel,
  NxtContactDataInputOptOutStatus,
  NxtContactDataInputOverrideText,
  NxtContactDataInputSelectedOption
} from '@nxt/ngx-ndbx-extensions/contact-data-input';
import {KommDataItem} from '../../../core/models/kommdata.model';
import {ContactDataTypePipe} from '../../../core/pipes/contact-data-type.pipe';

@Component({
  selector: 'dsmkd-contact-data-input',
  templateUrl: './contact-data-input.component.html',
  styleUrls: ['./contact-data-input.component.scss'],
})
export class ContactDataInputComponent implements OnInit, AfterViewInit {

  @ViewChild('field') field: NxtContactDataInputComponent;

  @Output() statusChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() fieldType: string;

  @Input() contactDataItems: KommDataItem [];

  @Input() optoutDisabled = false;

  @Input() preselectedValue: KommDataItem;

  constructor(private readonly contactDataTypePipe: ContactDataTypePipe) {
  }

  fieldText: NxtContactDataInputOverrideText = {};

  defaultEmailFieldText: NxtContactDataInputOverrideText;

  defaultMobileNumberFieldText: NxtContactDataInputOverrideText;

  model: NxtContactDataInputModel = {
    value: '',
    type: '',
    selectedValue: null,
    optOutStatus: null
  };

  options: NxtContactDataInputSelectedOption[] = [];

  ngOnInit(): void {
    this.initializeFields();
    this.initializeContactDataInputOverrideText();
    if (this.contactDataItems) {
      this.contactDataItems.forEach(contactData => {
        const option = {
          value: contactData.value,
          type: NXT_CONTACT_DATA_INPUT_CONTACT_TYPES[this.contactDataTypePipe.transform(contactData.type)]
        } as NxtContactDataInputSelectedOption;
        if (this.preselectedValue && this.preselectedValue.value === contactData.value) {
          this.model.selectedValue = option;
        }
        this.options.push(option);
      });
    }
  }

  ngAfterViewInit() {
    if (this.model.selectedValue === null && this.preselectedValue && this.preselectedValue !== null) {
      this.model.value = this.preselectedValue.value;
      this.model.type = this.preselectedValue.type;
    }
  }

  getNotSpecified(): boolean {
    return this.model.optOutStatus === NxtContactDataInputOptOutStatus.NOT_SPECIFIED;
  }

  markAsTouched(): void {
    this.field.markAsTouched();
  }

  valid(): boolean {
    if (this.field) {
      return this.field.valid;
    }
    return false;
  }

  // the field value for mobile number is always set to {+49, PRIVATE} per default
  getNewValueOfKommDataInputFieldItem(): KommDataItem {
    if (!this.model.selectedValue?.value && this.field && this.field.value.value) {
      const optOutStatus = this.field.value.optOutStatus;
      if (!optOutStatus) {
        return {
          type: this.field.value.type,
          value: this.field.value.value,
        } as KommDataItem;
      }
    }
    return null;
  }

  getKommDataItemValue(): string {
    return this.getKommDataItem().value;
  }

  getKommDataItem(): KommDataItem {
    const selectedAlreadyExistingValue = this.model.selectedValue;
    return selectedAlreadyExistingValue ? selectedAlreadyExistingValue : this.getNewValueOfKommDataInputFieldItem();
  }

  private initializeContactDataInputOverrideText() {
    // // Values are hard coded because of an ngx-translate bug
    // // https://github.com/ngx-translate/core/issues/1187
    if (this.fieldType === 'email') {
      this.fieldText = this.defaultEmailFieldText;
    }

    if (this.fieldType === 'phone') {
      this.fieldText = this.defaultMobileNumberFieldText;
    }
  }

  optOutStatusChange($event: any) {
    this.statusChanged.emit();
  }

  private initializeFields(): void {
    this.defaultEmailFieldText =
      {
        INFO: '',
        NOT_SPECIFIED: 'Keine Angabe',
        ERROR_REQUIRED: this.optoutDisabled ?
          'Bitte geben Sie eine gültige E-Mail-Adresse an.' :
          'Geben Sie eine E-Mailadresse an oder wählen Sie keine Angabe',
        TYPE_LABEL: 'E-mail-Typ'
      };

    this.defaultMobileNumberFieldText =
      {
        INFO: '',
        NOT_SPECIFIED: 'Keine Angabe',
        ERROR_REQUIRED: this.optoutDisabled ?
          'Bitte geben Sie eine gültige Mobilfunknummer an.' :
          'Geben Sie eine gültige Mobilfunknummer an oder wählen Sie keine Angabe',
        TYPE_LABEL: 'Telefon-Typ'
      };
  }

  sanitizePhoneInput(event: any) {
    const inputValue = event.target.value;

    const sanitizedValue = inputValue.replace(/[^0-9\s]/g, '');

    event.target.value = this.formatPhoneInput(sanitizedValue);
  }

  formatPhoneInput(inputValue: string): string {
    const inputWithoutSpaces = inputValue.replace(/\s+/g, '');

    // Add a space after the first two digits
    const formattedValue = inputWithoutSpaces.replace(/^(\d{2})/, '$1 ');

    return formattedValue.trim();
  }
}
